<template>
  <section class="mt-40">
    <br>
    <br>
    <br>
    <NavBody>
      <template #body>
        <p class="font-bold text-2xl text-brand-primary">Chats</p>
        <p class="font-normal text-base text-[#8C94A6]">Connect with recruiters</p>
      </template>
    </NavBody>
    <div class="mx-auto lg:max-w-[80rem] z-20 gap-6 w-full px-4 lg:px-0 bg-[#FAFAFA] h-[60vh]">
      <div class="flex bg-white border rounded-sm h-full w-full relative">
        <div class="w-full md:w-96 p-4 border-r-0 md:border-r h-[60vh] absolute md:relative z-30 left-0 top-0 bottom-0">
          <div class="flex gap-x-4 mb-4">
            <img :src="userDetails?.candidate?.avatar" class="w-[45px] h-[45px] rounded-full" alt="" />
            <div>
              <h3 class="font-semibold">{{ userDetails?.name }}</h3>
              <p>
                <small>{{ userDetails?.candidate?.role_title }}</small>
              </p>
            </div>
          </div>

          <div class="relative mb-6">
            <Search class="absolute w-10 top-3" />
            <input type="text" class="border-gray-400 w-full rounded-md overflow-x-hidden pl-10" placeholder="Search conversation" />
          </div>

          <div class="">
            <h4 class="mb-4">Recent Chats</h4>

            <div
              :class="['flex gap-x-4 py-2 mb-6 cursor-pointer', selectedConversation == item?.uuid ? 'bg-gray-100 py-3 px-2 rounded-md' : '']"
              v-for="item in allConversations"
              @click.stop="setSelectedConversation(item.uuid)"
              :key="item"
            >
              <img :src="item.profile_url" class="w-[45px] h-[45px] rounded-full" alt="" />
              <div class="flex-1">
                <h3 class="font-semibold">{{ item.name }}</h3>
                <p class="text-ellipsis overflow-hidden h-6">
                  <small>{{ item?.last_message?.content }}</small>
                </p>
              </div>
              <p>
                <small class="text-gray-400/70">{{ moment(item.created_at).fromNow() }}</small>
              </p>
            </div>
          </div>
        </div>

        <div :class="['w-full md:flex flex-col h-[60vh] absolute md:relative z-50 left-0 top-0 bottom-0 bg-white', selectedConversation ? 'flex' : 'hidden']">
          <div class="flex gap-x-4 border-b p-4 items-center">
            <img :src="selectedConversationDetails?.profile_url" class="w-[45px] h-[45px] rounded-full" alt="" v-if="selectedConversation" />
            <div class="flex-1">
              <h3 class="font-medium">{{ selectedConversation ? selectedConversationDetails?.name : 'Conversations' }}</h3>
            </div>
            <div class="justify-self-end inline-block md:hidden" @click="selectedConversation = null">
              <img :src="closeIcon" alt="" />
            </div>
          </div>

          <div :class="['flex-1 custom-scroll overflow-y-auto overflow-x-hidden', allConversations.length > 0 ? '' : 'grid place-content-center']">
            <div v-if="allConversations.length > 0">
              <div v-if="selectedConversation" class="p-6 bg-gray-50 w-full">
                <div
                  v-for="chat in sendChats"
                  :key="chat.id"
                  :class="['flex', chatInitiator(chat.sender_identifier) == 'employer' ? '' : 'justify-end']"
                >
                  <div class="bg-white shadow-sm border p-4 rounded-md mb-6 max-w-[70vw] md:max-w-[40vw]">
                    <pre v-html="chat.content" v-if="carriageText(chat.content)"></pre>
                    <p v-else v-html="chat.content"></p>
                    <p class="text-right">
                      <small class="text-xs">{{ moment(chat.created_at).fromNow() }}</small>
                    </p>
                  </div>
                </div>
              </div>
              <div class="mx-auto grid place-content-center mt-6" v-else>
                <h2 class="mx-auto mb-2 text-lg font-semibold text-center">
                  Start a conversation by clicking on the previous <br />
                  conversations lists on the left!
                </h2>
                <img :src="chatBubbleIcon" class="w-[100px] mx-auto" alt="" />
              </div>
            </div>
            <div class="mx-auto" v-else>
              <h2 class="mx-auto mb-2 text-xl font-semibold">No conversations available yet!</h2>
              <img :src="chatBubbleIcon" class="w-[100px] mx-auto" alt="" />
            </div>
          </div>

          <div class="relative border-t" v-if="selectedConversation">
            <form @submit.prevent="sendMsg">
              <input
                type="text"
                class="border-0 outline-none focus:outline-none focus:ring-0 w-full overflow-x-hidden pl-6 py-3"
                :disabled="!selectedConversation"
                v-model="chatMsg"
                placeholder="Type Message"
              />
            </form>
          </div>
        </div>
      </div>
      <Loading :is-loading="!pageLoaded" className="flex justify-center" />
    </div>
  </section>
</template>

<script setup>
import ChatFileSvg from '@/assets/icons/chat-file.svg?inline';
import ChatImageSvg from '@/assets/icons/chat-image.svg?inline';
import { ref, onMounted, computed, watch } from 'vue';
import Tab from '@/components/Tab.vue';
import ChatRoom from '@/components/ChatRoom.vue';
import Search from '@/assets/icons/search.svg?inline';
import closeIcon from '@/assets/icons/close.svg';
import { useStore } from 'vuex';
import NavBody from '@/components/NavBody.vue';
import Loading from '@/components/Loading.vue';
import chatBubbleIcon from '@/assets/images/speech-bubble.png';
import { useToast } from 'vue-toastification';
import { errorMessage } from '@/utils/helper';
import moment from 'moment';

const tabNumber = ref(1);
const container = ref(null);
const store = useStore();
const selectedConversation = ref(null);
const pageLoaded = ref(true);
const toast = useToast();
const sendChats = ref([]);
const chatMsg = ref('');
const loading = ref(false);
const tabs = ref([
  {
    name: 'General',
  },
  // {
  //   name: 'Archive',
  // },
]);
const changeTab = (value) => {
  tabNumber.value = value;
};
const scrollToBottom = () => {
  let el = container.value;
  el.scrollIntoView(false, {
    behavior: 'smooth',
    block: 'end',
  });
};

const userDetails = computed(() => {
  return store.getters['auth/userDetails'];
});

const allConversations = computed(() => {
  return store.getters['conversation/getSortedConversations'];
});

const setSelectedConversation = (uuid) => {
  selectedConversation.value = uuid;
};

const chatInitiator = (sender_identifier) => {
  if (sender_identifier.includes('emp')) {
    return 'employer';
  } else {
    return 'candidate';
  }
};

const carriageText = (text) => {
  return text.includes('\n');
};

watch(
  () => selectedConversation.value,
  async (newUuid, oldUuid) => {
    if (!newUuid) return;
    await getConversationMessages(newUuid);
  },
  { immediate: true },
);

const getConversationMessages = async (uuid) => {
  try {
    sendChats.value = await store.dispatch('conversation/getConversationMessages', { uuid });
    sendChats.value = sendChats.value.reverse();
  } catch (error) {
    toast.error(errorMessage(error), {
      timeout: 3000,
      hideProgressBar: true,
    });
  }
};

const selectedConversationDetails = computed(() => {
  return store.getters['conversation/getSortedConversations'].find((x) => x.uuid === selectedConversation.value);
});

const sendMsg = async () => {
  try {
    if (chatMsg.value) {
      loading.value = true;
      const participant = selectedConversationDetails?.value?.participants.filter(
        (participant) => chatInitiator(participant?.participant_identifier) == 'employer',
      );

      await store.dispatch('conversation/createConversation', {
        conversation: selectedConversation.value,
        receiver_identifier: participant[0]?.participant_identifier,
        type: 'text',
        url: null,
        content: chatMsg.value,
      });
      chatMsg.value = '';

      await getConversationMessages(selectedConversation.value);
    }
  } catch (error) {
    toast.error(errorMessage(error), {
      timeout: 3000,
      hideProgressBar: true,
    });
  } finally {
    loading.value = false;
  }
};
</script>

<style>
.conversation {
  height: calc(100vh - 60px);
}
</style>
